(function () {
    var GoogleMapsLoader = require('google-maps');
    GoogleMapsLoader.key = "AIzaSyAzB_AGsF2SGPVGQFOQ7gZOwCwclTrkIoI";
    GoogleMapsLoader.load(function (google) {

        var map;

        function initialize() {
            var glat = 51.19847;
                glong = 3.2310158;

            var mapOptions = {
                zoom: 18,
                scrollwheel: false,
                center: new google.maps.LatLng(51.19847, 3.2310158)
            };
            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                map: map,
                icon: '/bundles/sitefrontend/img/marker.png'
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();
